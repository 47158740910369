/* eslint-disable @next/next/no-html-link-for-pages */
import styles from "../styles/Home.module.css";
import {
  Box,
  Button,
  Center,
  SimpleGrid,
  Heading,
  Flex,
  Spacer,
  Square,
  Badge,
} from "@chakra-ui/react";
import Link from "next/link";
import React, { useState, useEffect } from "react";
import { Typeahead, withAsync } from "react-bootstrap-typeahead";
import { useRouter } from "next/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { GetCookie, GoSearch } from "../provider/common";
import { authLogout } from "../provider/auth";
import { useRecoilState } from "recoil";
import { cartCntState, estimateCntState } from "../atoms/state";
import { GetCartCnt } from "../pages/api/cart";
import { getEstimateDataCnt } from "../pages/api/estimate";

const AsyncTypeahead = withAsync(Typeahead);

export default function Header(props) {
  const router = useRouter();
  const { keyword } = router.query; //미리 할당을 해줘야 처리됨

  const [inputKeyword, setInputKeyword] = useState(keyword);
  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [cartBadge, setCartBadge] = useState("red");
  const [cartCnt, setCartCnt] = useRecoilState(cartCntState);
  const [estimateCnt, setEstimateCnt] = useRecoilState(estimateCntState);
  const [reload, setReload] = useState("");
  const [openMode, setOpenMode] = useState("close");

  useEffect(() => {
    isLoginCheck();
    showCnt();

    if (router.pathname === "/") {
      setReload("start");
    }
  }, []);

  const locationReload = () => {
    window.location.reload();
  };

  async function isLoginCheck() {
    const isLogin = await GetCookie("isLogin");
    setIsLogin(Number(isLogin));
  }

  const showCnt = async () => {
    setCartCnt(await GetCartCnt());
    //GetCartCnt();
    const estimateCnt = await getEstimateDataCnt();
    setEstimateCnt(estimateCnt);
  };

  // 로그아웃 기능
  const _handleLogout = async () => {
    await authLogout();
  };

  const handleCategoryMouseOver = function (param) {
    // samp();
    getNextCatgNodes(param, process.env.PANDA_API_URL);
    // console.log('Client function called from event handler!');
    console.log("Mouse is over with param:", param);
  };

  const _handleAutocomplete = (query) => {
    const enc_query = encodeURIComponent(query);
    setisLoading(true);
    setInputKeyword(query);
    fetch(
      process.env.SEARCH_API_URL +
        `/search?keyword=${enc_query}&type=autocomplete`
    )
      .then((resp) => resp.json())
      .then(({ data }) => {
        const options = data.hits.hits.map((i) => ({
          id: Number(i._id),
          login: i._source.it_name,
          it_name: i._source.it_name,
        }));
        setOptions(options);
        setisLoading(false);
      });
  };

  const onClick = async (e) => {
    e.preventDefault();
    if (!inputKeyword) {
      alert("검색어를 입력하세요");
    } else {
      window.location.href =
        "/search/lists?keyword=" +
        (inputKeyword.startsWith("%")
          ? inputKeyword
          : encodeURIComponent(inputKeyword));
    }
    return;
  };

  const onChange = (e) => {
    setInputKeyword(e.target.value);
  };

  const openModal = async () => {
    setOpenMode("open");
  };

  const closeModal = async () => {
    setOpenMode("close");
  };

  const pathname = router.asPath;
  // KR -> EN 변경시
  const transferLanguage = async () => {
    if (pathname === "/marketplace/market") {
      window.location.href = "/en";
    } else if (pathname === "/seller/event") {
      window.location.href = "/en/seller/event";
    } else if (pathname === "/board/fast?status=이용약관") {
      window.location.href = "/en/board/home?status=Terms";
    } else if (pathname === "/board/fast?status=개인정보취급방침") {
      window.location.href = "/en/board/home?status=Privacy";
    } else if (pathname === "/mypage/fast?path=주문내역") {
      window.location.href = "/en/mypage/home?path=Orders";
    } else if (pathname === "/mypage/fast?path=회원정보") {
      window.location.href = "/en/mypage/home?path=My%20Account";
    } else if (pathname === "/mypage/fast?path=배송지%20관리") {
      window.location.href = "/en/mypage/home?path=Address";
    } else if (pathname === "/cart/view") {
      window.location.href = "/en/cart/view";
    } else if (pathname === "/order/view") {
      window.location.href = "/en/order/view";
    } else if (pathname === "/marketplace/cart") {
      window.location.href = "/en/market/cart";
    } else if (pathname === "/auth/login") {
      window.location.href = `/en/auth/login`;
    } else if (pathname === "/auth/req_password") {
      window.location.href = `/en/auth/req_password`;
    } else if (pathname === "/auth/register") {
      window.location.href = `/en/auth/register`;
    } else {
      window.location.href = "/en";
    }
  };

  return (
    <>
      <Box className="header">
        <Box className="top-bar">
          <Box
            className="top-bar_box"
            variant="ghost"
            size="md"
            fontSize={14}
            p="0"
          >
            전자부품 온라인 구매 플랫폼 판다파츠
          </Box>
          {isLogin === 1 ? (
            <Box width="320px" className="flex-between">
              <Box width="90px" />
              <Box width="80px">
                <Link href="/mypage/fast?path=마이페이지">마이페이지</Link>
              </Box>
              <Box width="70px">
                <Link
                  colorScheme="gray"
                  variant="ghost"
                  size="md"
                  href="/board/fast?status=FAQ"
                >
                  고객센터
                </Link>
              </Box>
              <Button className="logout-btn" width="80px">
                <Box
                  width="20px"
                  className="flex-between top-bar_transfer_lang"
                >
                  {openMode === "open" ? (
                    <>
                      <Button className="logout-btn" onClick={closeModal}>
                        <i
                          className="xi-globus"
                          style={{ marginRight: "5px" }}
                        ></i>
                        KO
                      </Button>
                      <ul>
                        <li onClick={closeModal}>
                          <span>KO</span>
                          <span>ㅣ</span>
                          <span>한국어</span>
                          <span>
                            <i className="xi-check-thin"></i>
                          </span>
                        </li>
                        <li onClick={transferLanguage}>
                          <span>EN</span>
                          <span>ㅣ</span>
                          <span>English</span>
                          <span>
                            <i className="xi-check-thin"></i>
                          </span>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <span className="logout-btn" onClick={openModal}>
                      <i
                        className="xi-globus"
                        style={{ marginRight: "5px" }}
                      ></i>
                      KO
                    </span>
                  )}
                </Box>
              </Button>
              <Button
                onClick={() => _handleLogout()}
                className="logout-btn"
                width="80px"
              >
                로그아웃
              </Button>
            </Box>
          ) : (
            <Box width="20px" className="flex-between">
              <Button className="logout-btn" width="80px">
                <Box
                  width="20px"
                  className="flex-between top-bar_transfer_lang"
                >
                  {openMode === "open" ? (
                    <>
                      <Button className="logout-btn" onClick={closeModal}>
                        <i
                          className="xi-globus"
                          style={{ marginRight: "5px" }}
                        ></i>
                        KO
                      </Button>
                      <ul>
                        <li onClick={closeModal}>
                          <span>KO</span>
                          <span>ㅣ</span>
                          <span>한국어</span>
                          <span>
                            <i className="xi-check-thin"></i>
                          </span>
                        </li>
                        <li onClick={transferLanguage}>
                          <span>EN</span>
                          <span>ㅣ</span>
                          <span>English</span>
                          <span>
                            <i className="xi-check-thin"></i>
                          </span>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <span className="logout-btn" onClick={openModal}>
                      <i
                        className="xi-globus"
                        style={{ marginRight: "5px" }}
                      ></i>
                      KO
                    </span>
                  )}
                </Box>
              </Button>
              <Box width="60px" pl="10px" className="logIn">
                <Link href="/auth/login">로그인</Link>
              </Box>
              <Box width="60px">
                <Link href="/mypage/ordernum">주문확인</Link>
              </Box>
              <Box width="60px">
                <Link href="/board/fast?status=FAQ">고객센터</Link>
              </Box>
              <Box className="top-boar__register flex-center">
                <Link href="/auth/register">회원가입</Link>
              </Box>
            </Box>
          )}
        </Box>
        <Box className="search-bar">
          <Flex className="search-gnb">
            <Box>
              {reload === "start" ? (
                <Box className={"header-logo cursor"} onClick={locationReload}>
                  <img src="/images/pandalogo.png" alt="로고" />
                </Box>
              ) : (
                <Box className={"header-logo cursor"}>
                  <Link href="/" className="panda-logo">
                    <img src="/images/pandalogo.png" alt="로고" />
                  </Link>
                </Box>
              )}
            </Box>
            <Square>
              <div>
                <AsyncTypeahead
                  id="panda_search"
                  isLoading={isLoading}
                  className="top-bar_search-input"
                  size="lg"
                  color="333"
                  h="50px"
                  minLength={3}
                  labelKey="login"
                  defaultInputValue={
                    inputKeyword
                      ? inputKeyword.startsWith("%")
                        ? decodeURIComponent(inputKeyword)
                        : inputKeyword
                      : ""
                  }
                  value={keyword}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      if (event.target.value) {
                        event.preventDefault();
                        window.location.href =
                          "/search/lists?keyword=" +
                          encodeURIComponent(event.target.value);
                      } else {
                        alert("검색어를 입력하세요");
                      }
                      setInputKeyword("");
                    }
                  }}
                  onChange={onChange}
                  onSearch={_handleAutocomplete}
                  options={options}
                  placeholder="전자부품 번호(P/N)로 검색하세요!"
                  renderMenuItemChildren={(option, props) => (
                    <div
                      onClick={() => {
                        window.location.href =
                          "/search/lists?keyword=" +
                          encodeURIComponent(option.it_name);
                      }}
                    >
                      <span>{option.it_name}</span>
                    </div>
                  )}
                >
                  <button onClick={onClick}>
                    <FontAwesomeIcon
                      className="top-bar_search-input__search-icon"
                      icon={faSearch}
                    />
                  </button>
                </AsyncTypeahead>
              </div>
              <Box className={`${styles.search_list}`} pr="10px">
                <a
                  className={`${styles.search_list_slide} cursor`}
                  href="/search/lists?keyword=IPW60R070C6"
                >
                  <Box textAlign="left">IPW60R070C6</Box>
                </a>
                <a
                  className={`${styles.search_list_slide} cursor`}
                  href="/search/lists?keyword=STM32G071G8U6"
                >
                  <Box>STM32G071G8U6</Box>
                </a>
                <a
                  className={`${styles.search_list_slide} cursor`}
                  href="/search/lists?keyword=ISL8012IRZ-T"
                >
                  <Box>ISL8012IRZ-T</Box>
                </a>
                <a
                  className={`${styles.search_list_slide} cursor`}
                  href="/search/lists?keyword=SN74LVC07ARGYR"
                >
                  <Box ml="5px">SN74LVC07ARGYR</Box>
                </a>
              </Box>
            </Square>

            <Box flex="1" bg="">
              <SimpleGrid columns={2} marginLeft="40px" width="300px">
                <Box width="150px" height="80px" className="cursor">
                  <Link href="/estimate/lists">
                    <Center bg="" h="80px" color="white">
                      <img
                        src="/images/quatation.png"
                        alt="견적내역"
                        width="32"
                      ></img>
                      <Heading as="h6" size="sm" color="#555" p="2">
                        견적내역
                      </Heading>
                      <Badge
                        colorScheme="red"
                        padding={2}
                        borderRadius={30}
                        marginLeft={1}
                      >
                        {estimateCnt}
                      </Badge>
                    </Center>
                  </Link>
                </Box>
                <Box bg="" width="150px" height="80px">
                  <Box bg="" height="80px" className="cursor">
                    <Link href="/cart/view">
                      <Center bg="" h="80px" color="white">
                        <img
                          src="/images/basket.png"
                          alt="장바구니"
                          width="32"
                        ></img>
                        <Heading as="h6" size="sm" color="#555" p="2">
                          장바구니
                        </Heading>

                        <Badge
                          colorScheme={cartBadge}
                          p="2"
                          borderRadius={20}
                          marginLeft={1}
                        >
                          {cartCnt}
                        </Badge>
                      </Center>
                    </Link>
                  </Box>
                </Box>
              </SimpleGrid>
            </Box>
          </Flex>
        </Box>
        <Box
          borderTop="1px"
          borderBottom="1px"
          borderColor="#ededed"
          className="category-bar"
        >
          <Flex>
            <Box className={"header-link"}>
              <div className="category_mask" />
              <div className="category_wrap">
                <input id="dpid_use_" type="hidden" />
                <button
                  className="category_btn"
                  type="button"
                  onMouseOver={() => handleCategoryMouseOver("")}
                >
                  카테고리
                </button>
                <ul className="category_list"></ul>
              </div>
            </Box>
            <Spacer />

            <Box className={"header-link"}>
              <a
                href="/marketplace/market"
                style={{ width: "fit-content!important" }}
              >
                마켓플레이스
              </a>
            </Box>
            <Box className={"header-link"}>
              <a
                href="/parts/mallcategory/32"
                style={{ width: "fit-content!important" }}
              >
                오픈소스 보드
              </a>
            </Box>
            <Box className={"header-link"}>
              <a p="4" href="/board/fast_view?no=78&type=notice">
                후불결제
              </a>
            </Box>
            <Box className={"header-link"}>
              <a p="4" href="/benefit/affiliate">
                제휴파트너
              </a>
            </Box>
            <Box className={"header-link"}>
              <a p="4" href="/estimate/select">
                판다봇 견적
              </a>
            </Box>
            <Box className={"header-link combine"}>
              <a p="4" href="/estimate/request?type=manual">
                컴바인봇 견적
              </a>
            </Box>
            <Box className={"header-link"}>
              <a p="4" href="/board/fast?status=FAQ">
                고객센터
              </a>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
}
