import { GetCookie } from "../provider/common";
import { getUserInfo } from "../provider/auth";

// 전역변수
const axios = require("axios").default;
const MASTER_URL = process.env.MASTER_URL;

export async function registerBoard(type, subject, content, isEmail, filename) {
  if (subject === "" || subject === undefined || subject === null) {
    alert("제목은 필수항목입니다.");
    return;
  }

  if (content === "" || content === undefined || content === null) {
    alert("내용은 필수항목입니다.");
    return;
  }

  const data = {
    wr_subject: subject,
    wr_content: content,
    wr_2: isEmail,
    wr_option: null,
    file_name: filename,
  };
  try {
    if (type == "notice") {
      const URL = MASTER_URL + `/api/v2/board?type=notice`;
      const res = await axios
        .post(URL, data, {
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + (await GetCookie("token")),
          },
        })
        .then((res) => alert("공지사항이 등록되었습니다."));
      window.location.href = "/board/notice";
    } else {
      const URL = MASTER_URL + `/api/v2/board?type=qna`;
      const res = await axios
        .post(URL, data, {
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + (await GetCookie("token")),
          },
        })
        .then((res) => alert("문의글이 등록되었습니다."));
      window.location.href = "/board/qna";
    }
  } catch (e) {
    alert("등록이 실패하였습니다.");
  }
}

// 게시글 삭제 버튼
export async function deleteBoard(id, mb_id, type) {
  if (confirm("정말 삭제하시겠습니까?") == true) {
    // url 변수
    const DELETE_URL = `${MASTER_URL}/api/v2/board/${id}?type=${type}`;

    // 권한 체크
    let res = await getUserInfo();
    if (res.mb_level !== 10) {
      if (res.mb_id !== mb_id) {
        alert("권한이 없습니다.");
        return;
      }
    }

    try {
      let res = await axios.delete(DELETE_URL);
      if (res.data.data == "board") {
        window.location.href = "/board/qna";
      } else if (res.data.data == "reply") {
        return res.data.status;
      } else if (res.data.data == "notice") {
        window.location.href = "/board/notice";
      }
      return;
    } catch (e) {
      alert("삭제를 실패하였습니다.");
    }
  }
  return;
}

// QnA와 공지사항 검색 함수
export async function inputSearch(type, keyword) {
  const URL = MASTER_URL + `/api/v2/board?type=${type}&search=${keyword}`;
  try {
    const res = await axios.get(URL, {
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + (await GetCookie("token")),
      },
    });
    return res.data.data;
  } catch (e) {
    alert("검색 실패");
  }
  return;
}
// QnA와 공지사항 수정 함수
export async function updateBoard(id, contents, type) {
  if (confirm("정말 수정하시겠습니까?") == true) {
    // url 변수
    if (type == "notice") {
      var UPDATE_URL = `${MASTER_URL}/api/v2/board/${id}?type=notice`;
    } else {
      var UPDATE_URL = `${MASTER_URL}/api/v2/board/${id}?type=qna`;
    }
    try {
      const res = await axios.patch(
        UPDATE_URL,
        { contents: contents },
        {
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + (await GetCookie("token")),
          },
        }
      );
      return res.data.status;
    } catch (e) {
      alert("수정을 실패하였습니다.");
      return;
    }
  }
}
export async function addReplies(no, contents) {
  const URL = MASTER_URL + `/api/v2/board?type=qna`;
  const data = { parent_id: no, wr_content: contents };
  try {
    const res = await axios.post(URL, data, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer  ${await GetCookie("token")}`,
      },
    });
    return res.status;
  } catch (e) {
    console.log(e);
    alert("등록이 실패하였습니다.");
  }
}

export async function registerBoardEnglish(
  type,
  subject,
  content,
  isEmail,
  filename
) {
  const data = {
    wr_subject: subject,
    wr_content: content,
    wr_2: isEmail,
    wr_option: null,
    file_name: filename,
  };

  try {
    if (type == "notice") {
      const URL = MASTER_URL + `/api/v2/board?type=notice`;
      const res = await axios
        .post(URL, data, {
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + (await GetCookie("token")),
          },
        })
        .then((res) => alert("The announcement has been posted."));
      window.location.href = "/en";
    } else {
      const URL = MASTER_URL + `/api/v2/board?type=qna`;
      const res = await axios
        .post(URL, data, {
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + (await GetCookie("token")),
          },
        })
        .then((res) => alert("Successfully registered."));
      window.location.href = "/en";
    }
  } catch (e) {
    alert("Registration failed.");
  }
}
